var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-3",style:(_vm.view === 'table' ? 'min-height:1043px' : '')},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Library Archives")]),_c('v-spacer'),_c('span',{staticClass:"mr-2"},[_vm._v("View:")]),_c('v-btn-toggle',{staticClass:"mr-4",attrs:{"dense":""},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"table","aria-label":"View as Table"}},on),[_c('v-icon',[_vm._v("fal fa-table")])],1)]}}])},[_c('span',[_vm._v("View as a table")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"card","aria-label":"View as Cards"}},on),[_c('v-icon',[_vm._v("fal fa-id-card")])],1)]}}])},[_c('span',[_vm._v("View as cards")])])],1),_c('v-btn',{staticClass:"mr-4",attrs:{"to":"/library/archives","exact":""}},[_vm._v("Archive Categories")]),(_vm.isAdmin)?_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.addEntry}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add Entry ")],1):_vm._e()],1),_c('v-card-text',[_c('v-row',[(_vm.$route.params.category === 'All')?_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.categories,"label":"Category","outlined":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1):_vm._e(),(_vm.category === 'Capstone')?_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.capstoneMajors,"label":"Major","outlined":"","multiple":"","chips":"","small-chips":"","deletable-chips":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":_vm.capstoneMajor.includes(item.value)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.count)+" "+_vm._s(item.count === 1 ? 'Entry' : 'Entries'))])],1)]}}],null,false,2373331136),model:{value:(_vm.capstoneMajor),callback:function ($$v) {_vm.capstoneMajor=$$v},expression:"capstoneMajor"}})],1):_vm._e(),_c('v-col',[_c('v-text-field',{attrs:{"label":"Search","hint":"Searches only complete words","persistent-hint":"","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.yearOptions,"label":"Academic Year","outlined":"","multiple":"","chips":"","small-chips":"","deletable-chips":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":_vm.yearFilter.includes(item.value)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),(item.matchCount != null)?_c('v-list-item-subtitle',[_vm._v("Matched "+_vm._s(item.matchCount)+" out of "+_vm._s(item.count)+" "+_vm._s(item.count === 1 ? 'Entry' : 'Entries'))]):_c('v-list-item-subtitle',[_vm._v(_vm._s(item.count)+" "+_vm._s(item.count === 1 ? 'Entry' : 'Entries'))])],1)]}}]),model:{value:(_vm.yearFilter),callback:function ($$v) {_vm.yearFilter=$$v},expression:"yearFilter"}})],1)],1),(_vm.category === 'Chapel')?_c('v-alert',{staticClass:"mt-2",attrs:{"type":"info","colored-border":"","border":"left","elevation":"3"}},[_vm._v("Chapels from 1971-2003 were originally recorded on audio cassette tapes. Any cracking, popping, or background noise is on the original master. Some recordings may not contain the last few minutes of the service due to the length of the tape used.")]):_vm._e()],1),(_vm.view === 'table')?_c('v-data-table',{attrs:{"items":_vm.entries,"headers":_vm.headers,"loading":_vm.loading,"server-items-length":_vm.entryCount,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":"","disable-pagination":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"dblclick:row":function (e, ref) {
	var item = ref.item;

	return _vm.downloadItem(item);
}},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"pa-0",staticStyle:{"width":"48px"},attrs:{"elevation":"1"}},[_c('v-img',{staticStyle:{"width":"48px","height":"64px"},attrs:{"src":item.thumbnail || '/img/icons/msapplication-icon-144x144.png',"contain":""}})],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.date, true, true)))]}},{key:"item.format",fn:function(ref){
var item = ref.item;
return [(item.format === 'pdf')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-file-pdf")])]}}],null,true)},[_c('span',[_vm._v("Format: PDF")])]):(item.format === 'mp3')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-volume")])]}}],null,true)},[_c('span',[_vm._v("Format: MP3")])]):_c('span',[_vm._v(_vm._s(item.format))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isAdmin)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":'/library/archives/' + item._id,"disabled":!('fileLink' in item)}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-eye")])],1),_c('v-list-item-title',[_vm._v("View File")])],1),_c('v-list-item',{attrs:{"disabled":!('fileLink' in item),"href":'https://drive.google.com/uc?export=download&id=' + item.fileLink}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-download")])],1),_c('v-list-item-title',[_vm._v("Download File")])],1),(_vm.isAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-pencil")])],1),_c('v-list-item-title',[_vm._v("Edit Entry")])],1):_vm._e()],1)],1):('fileLink' in item)?_c('v-btn',{attrs:{"aria-label":"View File"},on:{"click":function($event){return _vm.downloadItem(item)}}},[_c('v-icon',[_vm._v("fal fa-eye")])],1):_vm._e()]}}],null,false,1065599589)}):(_vm.view === 'card')?_c('v-card-text',[_c('v-row',{staticClass:"pl-4 pr-4 pt-4 pb-2"},_vm._l((_vm.entries),function(ref){
var _id = ref._id;
var title = ref.title;
var date = ref.date;
var description = ref.description;
var thumbnail = ref.thumbnail;
var fileLink = ref.fileLink;
return _c('v-col',{key:_id,staticClass:"pa-0",attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-card',{staticClass:"ma-2 entry-card"},[_c('v-list-item',{on:{"click":function($event){_vm.isAdmin ? _vm.editItem({ _id: _id }) : _vm.downloadItem({ _id: _id, title: title, fileLink: fileLink })}}},[_c('v-list-item-action',[_c('v-card',{staticClass:"pa-0",staticStyle:{"width":"48px"},attrs:{"elevation":"3"}},[_c('v-img',{staticStyle:{"width":"48px","height":"64px"},attrs:{"src":thumbnail || '/img/icons/msapplication-icon-144x144.png',"contain":""}})],1)],1),_c('v-list-item-content',{staticStyle:{"max-height":"81px"}},[_c('v-list-item-title',{staticClass:"entry-title"},[_vm._v(_vm._s(title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.stringFormatDate(date).substring(0, 17)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(description))])],1)],1)],1)],1)}),1)],1):_vm._e(),_c('page-footer',{attrs:{"total":_vm.entryCount}}),(_vm.isAdmin)?_c('edit-dialog',{ref:"editDialog",attrs:{"id":_vm.selectedId},on:{"updated":_vm.updateRecord,"removed":_vm.loadResults}}):_vm._e(),_c('v-dialog',{attrs:{"width":"400","persistent":""},model:{value:(_vm.downloading),callback:function ($$v) {_vm.downloading=$$v},expression:"downloading"}},[_c('v-card',[_c('v-card-title',[_vm._v("Downloading...")]),_c('v-card-text',[_vm._v("Downloading the file. This should only take a few seconds...")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }